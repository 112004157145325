<template>
  <div>
    <div class="header">
      <title-vue :title="`Fase do dia ${formatDate($route.params.date)}`" />
      <status-badge-vue :status="phase.status" />
    </div>

    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <div v-else>
      <div class="header">
        <v-row class="pa-4">
          <v-col cols="2 mr-8">
            <card-resume-vue
              backgroundColor="#5A41F3"
              title1="Total de usuários no dia"
              :title2="String(phase.usersQuantity)"
            />
          </v-col>
          <v-col cols="2">
            <card-resume-vue
              backgroundColor="#24A859"
              title1="Valor total"
              :title2="formatPrice(phase.totalAmmount)"
            />
          </v-col>
        </v-row>
        <v-btn
          :disabled="phase.status == 'PAID' || loading || !isDistributionValid"
          :dark="phase.status != 'PAID' || !!isDistributionValid"
          color="#e5183d"
          @click="openDialog = true"
          >Liberar distribuição</v-btn
        >
      </div>
      <v-card>
        <v-data-table
          :headers="headers"
          :items-per-page="23"
          :items="phase.distributions"
          :footer-props="{ 'items-per-page-text': 'fases por página' }"
          :options="{ pagination: false }"
          class="elevation-0"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="d-block d-sm-table-cell">{{ item.missionId }}</td>
                <td class="d-block d-sm-table-cell">{{ item.usersQuantity }}</td>
                <td class="d-block d-sm-table-cell">
                  <v-text-field
                    :required="true"
                    v-money="money"
                    v-model="item.distributionPercent"
                    :readonly="item.usersQuantity <= 0"
                  />
                </td>
                <td class="d-block d-sm-table-cell">{{ formatPrice(item.individualAmount) }}</td>
                <td class="d-block d-sm-table-cell">{{ formatPrice(item.missionAmount) }}</td>
                <td class="d-block d-sm-table-cell">
                  <status-badge-vue :status="item.status || 'PENDING'" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog v-model="openDialog" width="600" persistent transition="dialog-top-transition">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h2 class="black--text">
            Deseja realmente liberar o pagamento do sistema de fases do dia
            {{ formatDate($route.params.date) }}?
          </h2>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="openDialog = false"> Não </v-btn>
          <v-btn elevation="0" dark color="#e5183d" @click="handlePaymentPhases"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";
import CardResumeVue from "@/components/cardResume/CardResume.vue";

import TitleVue from "@/components/title/Title.vue";
import { PhaseDetailType, PhaseDistributionType } from "@/types/phase/Phases";
import { Status } from "@/types/phase/Status";
import { GetPhase } from "@/services/phases/GetPhase";
import { PaymentPhases } from "@/services/phases/PaymentPhases";
import { UpdateDistribution } from "@/services/phases/UpdateDistribution";
import { format, parseISO } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  email: string;
  document: string;
}

export default defineComponent({
  components: { StatusBadgeVue, CardResumeVue, TitleVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,
      openDialog: false,
      phase: {
        distributions: [] as PhaseDistributionType[],
        status: null as Status,
        totalAmmount: 0,
        usersQuantity: 0,
      } as PhaseDetailType,
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Fase",
          value: "phase",
        },
        { text: "Quantidade de usuários", value: "usersQuantity" },
        { text: "Percentual da missão", value: "distributions.distributionPercent" },
        { text: "Total individual", value: "individualAmount" },
        { text: "Total da missão", value: "totalAmount" },
        { text: "Status", value: "status" },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        suffix: "%",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  methods: {
    async handleLoadPhase() {
      this.loading = true;
      const getPhase = new GetPhase();
      const phase = await getPhase.execute(this.$route.params.date);
      this.phase = phase;
      console.log(phase);
      this.loading = false;
    },
    async handlePaymentPhases() {
      this.loading = true;
      const distributions = this.phase.distributions.map(({ id, distributionPercent }) => {
        const formattedPercent = String(distributionPercent).replace("%", "").replace(",", ".");
        const distributionPercentFloat = parseFloat(formattedPercent);

        return {
          id,
          distributionPercent: distributionPercentFloat,
        };
      });
      const updateDistribution = new UpdateDistribution();
      await updateDistribution.execute(distributions);

      const paymentPhase = new PaymentPhases();
      await paymentPhase.execute(this.$route.params.date).then((res) => {
        if (res.status === 201) {
          this.$store.dispatch("showSnack", {
            message: "Sistema de fases pago com sucesso",
            color: "green darken-1",
          });
        } else {
          this.$store.dispatch("showSnack", {
            message: "Erro ao pagar o sistema de fases",
            color: "red",
          });
        }
      });
      this.handleLoadPhase();
      this.openDialog = false;
    },
    handleBrBonus() {
      this.$router.push(`/brbonus-distribution/${this.$route.params.date}`);
    },
    handleDetailClient(id: string) {
      this.$router.push(`/client/form/${id}`);
    },

    formatDate: (date: string): string => {
      const dateFormated = format(parseISO(date), "dd/MM/yyyy");
      return dateFormated;
    },

    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 4,
      }).format(value);
      return formattedValue;
    },
  },
  computed: {
    // Propriedade computada para validar a soma das distribuições
    isDistributionValid(): boolean {
      const totalPercent = this.phase.distributions.reduce((sum, item) => {
        const formattedPercent = parseFloat(
          String(item.distributionPercent).replace("%", "").replace(",", ".")
        );
        return sum + (isNaN(formattedPercent) ? 0 : formattedPercent);
      }, 0);

      return totalPercent === 100;
    },
  },
  mounted() {
    if (this.$route.params.date) {
      this.handleLoadPhase();
    } else {
      this.$router.back();
    }
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-data-footer {
  display: none !important;
}
</style>
@/services/distribution/GetPhase@/services/distribution/PaymentPhases
